#livePgContainer {
	text-align: center;

	#headerContainer {
		margin-bottom: 64px;
	}

	#livePgCard {
		min-height: 500px;

		#selLangModelContainer {
			justify-content: center;
		}

		#changeVizBtns{
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 39px;
			left: 0px;
			height: calc(100% - 39px);
			width: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			margin: auto 0;
			transition: opacity 0.5s ease-in;
			z-index: -1;
		}

		#changeVizBtns.hideVizOverlay {
			// display: none;
			opacity: 0;
			// height: 0px;
		}

		#changeVizBtns.showVizOverlay{
			z-index: 3;
			opacity: 1;
		}

		#vizCog, #vizCogClose{
			// float: right;	
			position: absolute;
			right: 16px;
			cursor: pointer;
			z-index: 4;
		}
		
		#vizCogClose{
			color: rgb(242, 242, 242);
		}

		#vizCog{
			color: #444;
		}

		#liveDecodeBtns{
			display: flex;
			justify-content: center;
		}
	}

	// #livePgCard:hover {
	// 	#changeVizBtns {
	// 		// display: block;
	// 		opacity: 100%;
	// 	}
	// }

	.recordingStateLbl {
		height: 40px;
		width: 100%;
		line-height: 20px;
		border: 1px solid #d4d4d5;
		// box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
		// position: relative;
		// top: -32px;
		text-align: center;

		& > i {
			font-size: 1.15rem;
		}
		&:nth-child(1) {
			font-size: 1.05rem;
		}

		&#readyToRecordState {
			// background: linear-gradient(211deg, #ff176b 4.64%, #3093cb 94.36%);
			// background:rgb(5, 196, 193);
			background: #277cfa;
			color: white;
		}

		&#inProgressState {
			text-align: left;
			padding-left: 44%;
			background-color: #ff4438;
			color: white;

			span:nth-child(2)::after {
				content: "";
				overflow: hidden;
				animation: ellipsis-repeat 1.9s infinite 0.3s;
				animation-fill-mode: forwards;
			}
		}

		@keyframes ellipsis-repeat {
			17% {
				content: ".";
			}
			33% {
				content: "..";
			}
			50% {
				content: "...";
			}
			67% {
				content: "... .";
			}
			83% {
				content: "... ..";
			}
			100% {
				content: "... ...";
			}
		}
	}

	#transcriptArea {
		min-height: 30vh;
		text-align: start;
		font-size: 110%;
		font-style: italic;
		color: rgb(48, 48, 48);
		font-weight: 600;
		margin-top: 20px;
		padding-left: 1.5em;
		padding-right: 1.5em;
	}

	#visualisationContainer {
		width: 100%;
		// z-index: -1;
		padding-left: 1.5em;
		padding-right: 1.5em;
		position: absolute;
		bottom: 50%;
	}
}
