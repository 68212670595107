#warningTextSegment {
	h1{
		margin-top: 36px;
	}
	
	i {
		color: firebrick;
		margin-top: 32px;
		margin-bottom: 24px;
		font-size: 6.5em;
	}

	p {
		color: indianred;
		// margin-top: 8px;
		margin-bottom: 4px;
	}
}

#refreshBtn{
	margin-top: 24px;
	margin-bottom: 24px;
}


#instructionsSegment{
	img{
		margin: auto;
	}
}