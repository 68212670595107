
.custom-modal-style {
  margin-top: 300px;
  width: 500px;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0,0,0,0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  text-align: center;
  }
