#cardContainer{
    background-color: #f8f8f8; 
    }

#textBox {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    font-size:"16px";
    outline: none;
}