
#changePwdContainer{
	width: 380px;

	h1{
		margin-bottom: 24px;
	}

	#submitBtn{
		margin-top: 24px;
	}

	#goBackBtn{
		margin-top: 8px;
	}
}